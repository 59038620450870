<script setup lang="ts">
useHead({
  script: [
    {
      src: 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
      async: true,
      defer: true,
    },
  ],
})

onMounted(() => {
  // @ts-ignore
  window.googleTranslateElementInit = () => {
    // @ts-ignore
    new google.translate.TranslateElement(
      {
        pageLanguage: 'nl',
        layout:
          // google.translate.TranslateElement.InlineLayout.SIMPLE
          // google.translate.TranslateElement.InlineLayout.HORIZONTAL_BAR
          // google.translate.TranslateElement.InlineLayout.VERTICAL_DROPDOWN_ONLY
          // @ts-ignore
          google.translate.TranslateElement.InlineLayout.VERTICAL_DROPDOWN_ONLY,
        autoDisplay: false,
      },
      'google_translate_element',
    )
  }
})

// const selectedLanguage = ref('nl') // Default language

// const changeLanguage = () => {
//   const googleTranslateButton = document.getElementsByClassName(
//     'goog-te-gadget-simple',
//   )

//   if (googleTranslateButton.length > 0) {
//     googleTranslateButton[0].dispatchEvent(new Event('click'))

//     setTimeout(() => {
//       // @ts-ignore
//       const iframe = document.querySelector(
//         'iframe.skiptranslate',
//       ).contentWindow
//       console.log(`iframe`, iframe)
//       //const iframe = document.querySelector('.goog-te-menu-frame')
//       const links = iframe.document.querySelectorAll('span')

//       console.log(`links`, links)

//       for (let i = 0; i < links.length; i++) {
//         console.log(links[i].innerHTML, selectedLanguage.value)
//         if (links[i].innerHTML === selectedLanguage.value) {
//           console.log(`click`, links[i].parentElement)
//           links[i].parentElement.click()
//           break
//         }
//       }

//       console.log(`Language not found!`, selectedLanguage.value)
//     }, 2000) // Adding a timeout to allow the iframe contents to load
//   } else {
//     console.error('Google Translate element not found')
//   }
// }
</script>

<template>
  <div class="">
    
    <div class="bg-muted-50 rounded-xl p-4">
      <div id="google_translate_element" />
    </div>

    
    <!-- <select v-model="selectedLanguage" @change="changeLanguage">
      <option value="Dutch">Dutch</option>
      <option value="English">English</option>
      <option value="French">French</option>
    </select> -->
  </div>
</template>
